.Loader {
  padding-top: 80px;
  width: 100%;
  text-align: center;

  span,
  svg {
    display: block;
    margin: 0 auto;
  }

  span {
    margin-top: 10px;
  }

  svg {
    width: 30px;
    height: 30px;
    animation-name: spinner;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
  }
}

@keyframes spinner {
  from {
    transform: none;
  }
  to {
    transform: rotate(360deg);
  }
}
