@import url("https://use.typekit.net/sue3oba.css");

//Headings

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 34px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 22px;
  color: $dune;

  @include md{
    margin-bottom: 30px;
  }
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}

//Copy

p,
li,
div {
  font-size: 14px;
  font-weight: 300;
  color: $dune;
}

p {
  line-height: 27px;

  + p {
    margin-top: 15px;
  }
  a {
    letter-spacing: 0px;
  }
}
th {
  letter-spacing: 2px;
}

//Links
a {
  color: $primary;
  text-decoration: none;
}

button,
a {
  letter-spacing: 1px;
}
