// Columns
.col-50 {
  @include lg {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding-left: 25px;

    &:first-child {
      padding-left: 0;
      padding-right: 25px;
    }
  }
}

.col-33 {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding-left: 20px;
  padding-right: 20px;

  @include lg {
    width: 33.333%;
  }
}
