$white: #ffffff;
$black: #000000;
$grey: #f6f6f6;
$lightGrey: #f7f7f7;

$primary: #cc3300;

$whiteSand: #f6f6f6;
$catSkillWhite: #f4f8fa;
$gallery: #ededed;
$dune: #2d2926;
$tuna: #303038;
$scooter: #22b8d7;
$iron: #cfd2d3;
$funGreen: #028a3d;
$darkGrey: #e5e5e5;
$widgetGrey: #ebebeb