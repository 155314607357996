table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 15px 25px;
  text-align: left;
  font-weight: 500;
}

th {
  text-transform: uppercase;
  font-size: 18px;
  background-color: $primary;
  color: $white;
}

td {
  font-size: 16px;
  border: none;
}

tr:nth-child(even) {
  background: $catSkillWhite;
}
tr:nth-child(odd) {
  background: $white;
}
