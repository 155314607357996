@import '../../../GlobalStyles/variables.scss';

.Notification {
  display: block;
  position: relative;
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  background: $primary;
  font-size: 14px;
  font-weight: 500;
  z-index: 100;
  text-align: center;
  padding: 15px 50px;

  @include xl{
    position: relative;
    top: 0;
    left: 0;
  }

  @media (max-width: #{$screen-lg-min}) {
    position: absolute;
    left: 0;
    right: 0;
    font-weight: 300;
  }

  svg {
    transform: rotate(-90deg);
    position: absolute;
    right: 25px;
    margin-top: 3px;
  }
}

.left {
  text-align: left;
  padding: 15px 25px;
}
