@import '../../../GlobalStyles/variables.scss';

.Today {
  display: block;
  position: fixed;
  width: 100%;
  background: $white;
  z-index: 999;
  top: 140px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.5s;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include sm {
    top: 185px;
  }

  @include xl {
    top: 120px;
    height: calc(100vh - 120px);
    &:before {
      content:'';
      display: block;
      position: fixed;
      top: 120px;
      left: 0px;
      height: 5px;
      z-index: 99;
      width: 100%;
      background: $primary;
    }

    a[class*=NotificationStrip_Notification] {
      position: fixed;
      top: auto;
    }
  }

   &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
    transition: opacity 0.5s;
   }

   &.inline {
    z-index: 998;
    position: relative;
    top: 0;
    height: auto;
    overflow: visible;

    @include xl {

      div[class*='back_desktop'] {
        top: 19px;
      }
    }
   }

  &__back {
    display: block;
    padding: 30px 20px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    background: $whiteSand;
    width: 100%;
    text-transform: uppercase;
    text-align: left;
    cursor: pointer;

    @include xl {
      background: none;
      position: absolute;
    }


    svg {
      transform: rotate(90deg);
      margin-right: 20px;
      height: 10px;
      width: 18px;
    }

    &_desktop {
      display: none;

      @include xl {
        display: block;
        position: absolute;
        right: 60px;
        top: 90px;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    &_mobile {
      @include xl {
        display: none;
      }
    }

  }

  &__Content {
    max-width: 1270px;
    margin-left: auto;
    margin-right: auto;
  }

  &__Opening {
    display: block;

    @include xl {
      padding: 40px 165px;
      margin-top: 50px;
    }


    &_desktop {
      display: none;

      @include xl {
        display: block;
      }
    }

    &_mobile {
      @include xl {
        display: none;
      }
    }

    &_Title {
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 1px solid $gallery;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      padding: 18px 20px;
      text-transform: uppercase;
      text-align: left;

      svg {
        @media (max-width: #{$screen-md-min}) {
          transform: rotate(0deg);
        }
        transform: rotate(180deg);
        transition: transform 0.25s ease-in-out;
        position: absolute;
        right: 25px;
      }

      &.active {
        svg {
          @media (max-width: #{$screen-md-min}) {
            transform: rotate(180deg);
          }
          transform: rotate(0deg);
        }
      }

      &_desktop {
        font-size: 24px;
        line-height: 34px;
        color: $dune;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }

  &__Accordion {
    height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s;
    margin-bottom: 50px;
    display: block;

    @include xl {
      opacity: 1;
      float:left;
      width:410px;
      height:auto;
    }

    &_Wrap {
      padding: 25px;
    }
    &_Heading {
      display: block;
      font-weight: 500;
      font-size: 16px;
      color: $dune;
      line-height: 30px;
      height: 30px;
    }
    &_Text {
      display: block;
      font-weight: 300;
      font-size: 14px;
      color: $dune;
      line-height: 30px;
      height: 30px;
    }

    @include xl {
      &_Wrap {
        padding: 20px 0px;
      }
    }

    &.active {
      height: auto;
      opacity: 1;
    }
  }



  &__Carousel {

    &__Wrap {
      display: block;
      width: 100%;
      padding: 0 20px;

      > div {
        margin-bottom:40px;
      }

      @include sm {
        padding: 0;
      }

      @include xl {
        overflow: hidden;
        width: auto;
        direction: rtl;

        > div {
          width:50%;
          display:inline-block;
          vertical-align: top;
          direction: ltr;

          &:first-child:last-child {
            width:100%;
          }
        }
      }
    }

    /*@include xl {
      &__Single {
        display: inline-block;
        width: 50%;
        vertical-align: top;
      }
    }*/
  }
}

.todayTimes {
  @media (max-width: #{$screen-md-min}) {
    padding: 25px 25px 0 25px;
  }
}
