@import "../../../GlobalStyles/variables";

.weekTable {
  display:flex;
  background:$white;
  flex-direction: column;
  flex-wrap: wrap;
  border-right:$gallery 1px solid;
  border-bottom:$gallery 1px solid;
  font-size: 0.875rem;
  text-align: center;
  text-transform: none;
  max-height: 100%;
  width: 100%;
  max-width:100%;
  font-weight: 300;

  @include md {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  > div {
    @include md {
      border-left: 1px solid $gallery;
    }

    border-top: 1px solid $gallery;
    box-sizing: border-box;
    padding:7px 15px;
    line-height:1.6em;

    strong {
      font-weight:500;
    }
    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom:$gallery 1px solid;

      @media (max-width: 992px) {
        border-bottom: none;
      }
    }
  }
}

@media all and (-ms-high-contrast:none) {
  .weekTable {
    min-width:0px!important;
    width:auto;
    max-height:none;
    > div {
      box-sizing: border-box;
      border-right: 0;
      text-align:left;
      &:nth-child(4) {
        border-right: 0;
      }
    }
  }
}
