@import '../../../GlobalStyles/variables.scss';

.SearchBar {
  position: fixed;
  right:0;
  top:0;
  left:0;
  overflow:hidden;
  z-index: 999;
  pointer-events: none;

  &__Holder {
    transition: transform 0.5s linear;
    transform:translateX(100%) scale(0,1);
    background:#fff;
    border-bottom: $gallery 1px solid;
    width:100%;
    pointer-events: all;
  }

  &.active {
    > div {
      transform:translateX(0%) scale(1,1);
    }
  }

  &__Contents {
    transition: transform 0.5s linear;
    display:flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    position:relative;
    flex-wrap: nowrap;
  }

  &__Close {
    font-size: 12px;
    padding: 10px;
    vertical-align: middle;
    margin-top: 0;
    background: transparent;
    padding: 0px;
    display: inline-block;
    position:absolute;
    right:25px;
    top:32px;
    cursor:pointer;


    @include sm {
      top: 56px;
    }
    @include xl {
      top:50px;
    }
  }

  &__Label {
    padding:34px 0px 31px 26px;
    line-height: 1em;


    & svg {
      vertical-align: middle;
      margin-right:5px;
    }
    @include sm {
      padding:0 5.1vw 0 5.1vw;
      line-height: 128px;
      vertical-align: middle;
      margin-bottom: -1px;

      & svg {
        margin-right:25px;
      }
    }

  }

  &__LabelText {
    display: none;
    font-weight: 500;
    font-size:1rem;

    @include sm {
      display: inline-block;
      white-space: nowrap;
      vertical-align: middle;
    }
  }

  &__FieldHolder {
    padding:31px 46px 0px 20px;
    flex-grow: 1;
    margin-bottom: -1px;

    & ul {
      margin:25px -46px 0 -71px;
      list-style:none;
      padding:0;
      border:$gallery 1px solid;
      right:0;
      margin-top:25px;
      text-align:left;
      background-color:$white;

      & li {
        border-bottom:$gallery 1px solid;
        &:last-child {
          border-bottom:0;
        }
      }
    }

    @include sm {
      padding:0;
      border-left: $gallery 1px solid;

      & ul {
        position:static;
        margin-top:0;
        margin-left:0px;
        margin-right:0px;
        border-top-width:0;
        border-left-width:0;
      }
    }
  }

  &__Field {
    border:0;
    width:100%;
    height:20px;
    vertical-align: middle;
    font-size: 1rem;

    &:focus {
      border: none;
    }
    &::placeholder {
      color:#ced1d3;
      padding-left:20px;
    }

    @include sm {
      padding:0 4.8vw 0vw 4.8vw;
      height:auto;
      border-bottom:$gallery 1px solid;
      line-height: 130px;
      height: 130px;
      vertical-align: middle;
    }
    @include xl {
      line-height: 131px;
      height: 131px;
    }

    &::-ms-clear {
      display: none;
    }
  }

  &__Suggestion {
    display:block;
    border-left:$white 10px solid;
    padding:14px 10px 14px 60px;
    color:$dune;
    text-decoration: none;
    cursor:pointer;
    transition: all 0.2s linear;

    &:hover {
      border-left-color:$primary;
      text-decoration: underline;
      background-color:$gallery;
    }

    @include sm {
      padding-left:calc(4.6vw - 10px);
    }
  }
  &__SuggestionHightlight {
    font-weight: bold;
  }
}
