@import "../../../GlobalStyles/variables.scss";

.OpeningTimesSingleTab {
  border-radius: 0 0 5px 5px;
  padding: 5px;
  background-color: $primary;
  color: $white;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 1px;
  position: absolute;
  top: 0;
  left: 40px;
  z-index: 5;
}

.OpeningTimesSingleTabAlt {
  left: auto;
  right:0;
  border-radius: 0 4px 0 4px ;
}

.full {
  position: absolute;
  top: -130px;
  padding: 15px;
  margin: -70px 0 0px -7%;
  height: 50px;
  width: 120%;
  background-color: $primary;
  border-radius: 0px;
  z-index: 9;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;

  @include lg {
    display: none;
  }
}

.block {
  position: static!important;
  display: block;
  width: 100%;
  margin: 0px;
}
