@import url(~slick-carousel/slick/slick.css);
/* Slider */
.slick-loading .slick-list {
  background: #fff url("../../../Assets/icons/load-icon.svg") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
  .slick-prev:hover svg path,
  .slick-next:hover svg path {
    fill: #cc3300 !important; }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0.25; }
  @media (max-width: 1400px) {
    .slick-prev,
    .slick-next {
      display: none; } }
  .slick-prev svg,
  .slick-next svg {
    width: 32px;
    height: 17px; }
    .slick-prev svg path,
    .slick-next svg path {
      transition: all 0.2s linear;
      fill: #cfd2d3; }

.slick-prev {
  left: -25px;
  margin-top: -40px;
  margin-left: -40px;
  transform: rotate(90deg); }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }

.slick-next {
  right: -25px;
  margin-top: -40px;
  margin-right: -40px;
  transform: rotate(-90deg); }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
    .slick-dots li button:before {
      content: "";
      width: 6px;
      height: 6px;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.2s linear;
      border: 2px solid #000000;
      background: #000000;
      box-sizing: border-box; }
    .slick-dots li.slick-active button:before {
      width: 10px;
      height: 10px;
      background: transparent !important; }
    .slick-dots li:not(.slick-active) button {
      cursor: pointer; }

.Today_Today__Carousel__Single__PFj3z .slick-slider {
  margin-bottom: 40px; }
  .Today_Today__Carousel__Single__PFj3z .slick-slider .slick-dots li.slick-active button:before {
    width: 10px;
    height: 10px; }

/* Dark Mode */
.Slider-dark-bg .slick-dots li button:before {
  background: #ffffff;
  border-color: #ffffff; }

.Slider-dark-bg .slick-arrow svg path {
  fill: #ffffff; }

.Slider__title {
  padding: 0px 25px;
  letter-spacing: 2px;
  text-transform: uppercase; }
  .Slider__title--white {
    color: #ffffff; }
  .Slider__title--dark {
    color: #2d2926; }
  @media (max-width: 576px) {
    .Slider__title {
      padding: 0px; } }

.Slider__wrapper {
  margin-bottom: 60px; }

.Slider-with-cta .slider__Custom-dot-container {
  bottom: -60px; }

.Slider__CTA {
  display: block;
  width: calc(100% - 50px);
  margin: -30px auto 30px auto;
  border-radius: 25px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.88px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  padding: 11px 10px;
  transition: all 0.2s linear;
  z-index: 3;
  position: relative; }
  .Slider__CTA:hover {
    background: transparent !important;
    color: #fff !important;
    border-color: #fff !important; }
  .Slider__CTA.light_bg:hover {
    color: inherit !important;
    border-color: inherit !important; }
  @media (max-width: 576px) {
    .Slider__CTA {
      width: 100%; } }
  @media (max-width: 375px) {
    .Slider__CTA {
      margin-top: -50px; } }

.slider__Custom-dot-container {
  max-width: 100px;
  max-height: 20px;
  overflow: hidden;
  margin: auto; }
  .slider__Custom-dot-container .slick-list .slick-track .slick-current .slider__Custom-dots .slider__Custom-dot {
    width: 10px;
    height: 10px;
    background: transparent !important; }
  .slider__Custom-dot-container .slider__Custom-dots {
    height: 10px;
    width: 10px;
    position: relative;
    cursor: pointer; }
    .slider__Custom-dot-container .slider__Custom-dots .slider__Custom-dot {
      width: 6px;
      height: 6px;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.2s linear;
      border: 2px solid #ffffff;
      background: #ffffff;
      box-sizing: border-box; }
    .slider__Custom-dot-container .slider__Custom-dots.black-dots .slider__Custom-dot {
      border: 2px solid #2d2926;
      background: #2d2926; }
