@import "../../../GlobalStyles/variables.scss";
@import "~slick-carousel/slick/slick.css";
@import "./Carousel-slick-theme.scss";

.Slider__title {
  padding: 0px 25px;
  letter-spacing: 2px;
  text-transform: uppercase;

  &--white {
    color: $white;
  }
  &--dark {
    color: #2d2926;
  }

  @include sm-only {
    padding: 0px;
  }
}

.Slider__wrapper {
  margin-bottom: 60px;
}

// Slider with CTA
.Slider-with-cta .slider__Custom-dot-container {
  bottom: -60px;
  // @media (max-width: #{$screen-xl-min}) {
  //   bottom: -110px;
  // }
}

// Slider CTA button
.Slider__CTA {
  display: block;
  width: calc(100% - 50px);
  margin: -30px auto 30px auto;
  border-radius: 25px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.88px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  padding: 11px 10px;
  transition: all 0.2s linear;
  z-index: 3;
  position: relative;

  &:hover {
    background: transparent!important;
    color: #fff!important;
    border-color: #fff!important;
  }

  &.light_bg:hover {
    color: inherit!important;
    border-color: inherit!important;
  }

  @include sm-only {
    width: 100%;
  }
  @media(max-width:375px) {
    margin-top:-50px;
  }
}

.slider__Custom-dot-container {
  max-width: 100px;
  max-height: 20px;
  overflow: hidden;
  margin: auto;
  .slick-list {
    .slick-track {
      .slick-current {
        .slider__Custom-dots {
          .slider__Custom-dot {
            width: 10px;
            height: 10px;
            background: transparent !important;
          }
        }
      }
    }
  }
  .slider__Custom-dots {
    height: 10px;
    width: 10px;
    position: relative;
    cursor: pointer;
    .slider__Custom-dot {
      width: 6px;
      height: 6px;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.2s linear;
      border: 2px solid $white;
      background: $white;
      box-sizing: border-box;
    }
    &.black-dots .slider__Custom-dot{
      border: 2px solid $dune;
      background: $dune;
    }
  }
}
