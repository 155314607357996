@import '../../../GlobalStyles/variables.scss';

.Directory__Card {
  padding: 0px 20px;

  @include sm {
    display: inline-block;
    width: calc(50% - 25px);
    padding: 0px;
    vertical-align: top;
    margin-bottom:50px;
  }
  @include lg {
    display: inline-block;
    width: calc(33.3% - 25px);
  }
}
