@import "./GlobalStyles/GlobalStyles.scss";

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "gill-sans-nova", sans-serif;
  font-style: normal;
  // outline: none;
}
html,
body {
  overflow: auto;
}
html,
body,
#root {
  min-height: 100%;
}
#root {
  overflow-x: hidden;
}
html.noscroll {
  overflow-y: hidden;
  body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

strong {
  font-weight: 600;
}
.cookieConsent {
  align-items: center !important;
  justify-content: center !important;
}
.consent-content {
  p {
    color: $white;
  }
}

.btn-Cookie {
  background: #ff4000;
  display: block;
  height: 40px;
  padding: 0px 20px;
  line-height: 40px;
  margin: 15px;
  border: 1px solid #ff4000;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  flex: 0 0 auto;

  &:hover {
    background: white;
    color: #ff4000;
  }
}
