@import "../../../GlobalStyles/variables.scss";

.Button {
  border: 1px solid $primary;
  padding: 10px 34px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.88px;
  line-height: 26px;
  transition: background 0.2s linear, color 0.2s linear,
    border-color 0.2s linear;
  display: inline-block;
  margin-top: 40px;
  cursor: pointer;
  white-space: nowrap;
  @include sm {
    font-size: 14px;
  }

  @include sm-only {
    padding: 0.5em 1em 0.6em;
  }

  &__Fullwidth {
    display: block;
    width: 100%;
  }

  &.active {
    &:after {
      bottom: -37px;
    }
  }

  &__primary {
    background: $primary;
    color: $white;

    &.active,
    &:hover,
    &:active,
    &:focus {
      background: #ff4000;
      color: $white;
      border-color: #ff4000;

      svg path {
        fill: $primary;
        transition: fill 0.2s;
      }
    }

    &_blue {
      background: $scooter;
      border-color: $scooter;

      &.active,
      &:hover,
      &:active,
      &:focus {
        color: $scooter;

        svg path {
          fill: $scooter;
        }
      }
    }

    &_grey {
      background: $whiteSand;
      border-color: $whiteSand;
      color: $black;

      svg path {
        fill: $black;
        transition: fill 0.2s;
      }
    }
    &_darkgrey {
      background: $darkGrey;
      border-color: $darkGrey;
      color: $black;
    }
  }

  &__secondary {
    color: $black;
    background: $white;
    border-color: $black;

    &.active,
    &:hover,
    &:active,
    &:focus {
      background: $black;
      color: $white;

      svg path {
        fill: $primary;
      }
    }

    &_simple {
      padding-left: 0;
      padding-right: 0;
    }

    &_orange {
      color: $primary;
      border-color: $primary;

      svg path {
        fill: $primary;
      }

      &.active,
      &:hover,
      &:active,
      &:focus {
        color: $primary;

        svg path {
          fill: $primary;
        }
      }
    }

    &_blue {
      color: $scooter;
      border-color: $scooter;

      &.active,
      &:hover,
      &:active,
      &:focus {
        color: $scooter;

        svg path {
          fill: $scooter;
        }
      }
    }
  }

  &__primary,
  &__secondary {
    &_rounded {
      border-radius: 25px;
    }

    svg {
      vertical-align: text-bottom;
      margin-left: 7px;
    }
  }
}

// Disabled
.Button:disabled,
button:disabled,
button[disabled] {
  border-color: $gallery;
  color: $iron;
  background-color: $gallery;
  cursor: not-allowed;
}

.booking_style {
  // background-color: blue;
  /* position: absolute; */
  /* top: -20vh; */
  /* right: -32vw; */
  height: auto;
  width: auto;
  border-radius: 25px 0px 0px 25px;
  /* transform: translateX(100%); */
  z-index: 99;
  transition: all 0.25s ease-in-out;
  /* margin-right: 0; */
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 20px;
  &::after {
    content: " ";
    background-image: url(../../../Assets/icons/chevron-white.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 20px;
    width: 20px;
    transform: rotate(-90deg);
    margin-left: 20px;
  }

  @media (max-width: #{$screen-md-min}) {
    margin: 0;
    flex: 1;
    border-radius: 0;
    text-align: -webkit-auto;
    &::after {
      margin-left: auto;
    }
    &::before {
      content: " ";
      background-image: url(../../../Assets/icons/calendar-icon-white.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 20px;
      width: 20px;
      margin-right: 20px;
    }
  }
}

.booking_widget_style {
  background: none;
  border: none;
  color: $black;
  &_selected {
    width: inherit;
    background: inherit;
    border: inherit;
    color: inherit;
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      display: block;
      background: #cc3300;
    }
    &:hover,
    &:focus,
    &:active {
      width: inherit;
      background: inherit;
      color: inherit;
    }
  }
  &:hover,
  &:focus,
  &:active {
    width: inherit;
    background: inherit;
    color: inherit;
  }

  // --- underline animation ---
  &::after {
    content: "";
    width: 0px;
    height: 3px;
    display: block;
    background: #cc3300;
    transition: 300ms;
  }

  &:hover::after,
  &:focus::after,
  &:active::after {
    width: 100%;
  }
}
