@import "../../../GlobalStyles/variables.scss";

.Header {
  font-family: gill-sans-nova, sans-serif;
  font-style: normal;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  z-index: 999;
  background-color: white;
  padding-bottom: 45px;

  #skiptocontent{
    opacity: 0;
    position: absolute;
    left: 0px;
    top: 0px;
    &:focus{
      opacity: 1;
    }
  }

  @include lg {
    padding-bottom: 0;
  }

  &__logolink {
    margin-left: 25px;
    @include sm {
      margin-left: 40px;
    }
    @include xl {
      margin-left: 30px;
    }
    @media(min-width: 1550px){
      margin-left: 80px;
    }
  }
  .Logo {
    width: 172px;
    padding: 0;
    display: block;
    text-align: left;
    vertical-align: middle;

    @include sm {
      width: 247px;
    }
  }
  &__top {
    display: table;
    max-width: 1600px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 86px;
    border-bottom: 1px solid $gallery;
    @include sm {
      height: 131px;
    }
    @include xl {
      border-bottom: 0;
    }

    .Header__wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      flex: 1;

      @include sm {
        padding-left: 35px;
      }
      @media(min-width: 1470px){
        padding-left: 66px;
      }
      @include xl {
        margin-right: 101px;
      }
    }

    .Opening {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: $white;
      border-bottom: 1px solid $gallery;
      padding: 11px 25px 13px;
      text-align: left;
      left: 0%;
      z-index: 0;
      margin-right: auto;
      @media (max-width: #{$screen-sm-min}) {
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        span,a {
          font-size: 13px;
        }
        a {
          margin-left: auto;
        }
      }

      @include sm {
        text-align: center;
      }
      @include lg {
        padding: 0;
        line-height: 24px;
        position: static;
        border: none;
        display: inline-block;
        vertical-align: middle;
        width: auto;
        text-align: left;
        white-space: nowrap;
        float: none;
        margin-left: auto;
        margin-right: 42px;
      }
      @include xl {
        float: left;
        margin-left: 0;
        margin-right: auto;
      }

      &__text {
        font-size: 14px;
        font-weight: 500;
        margin-right: 5px;
        @include lg {
          font-size: 16px;
        }
        @media(min-width:1024px) {
          font-size: 14px;
        }

        &--light {
          font-size: 14px;
          font-weight: 400;
          @include lg {
            font-size: 16px;
          }
          @media(min-width:1024px) {
            font-size: 14px;
          }
        }
      }
      &__link {
        margin: 0;
        text-decoration: underline;
        color: $primary;
        font-size: 14px;
        font-weight: 500;
        border: none;
        letter-spacing: normal;
        float: right;
        &:hover,
        &:focus,
        &:active {
          color: $funGreen;
        }
        @include sm {
          margin-left: 30px;
          float: none;
        }
        @include lg {
          font-size: 16px;
          margin-left: 19px;
        }
        @include xl {
          margin-left: 17px;
          font-size: 14px;
        }
        @media(min-width:1440px){
          margin-left: 30px;
        }
      }
    }

    .Menu {
      display: inline-block;
      margin-right: 25px;
      vertical-align: middle;
      background: $white;
      border: none;
      cursor: pointer;

      &__toggle {
        display: block;
        width: 29px;
        & > span {
          display: block;
          height: 2px;
          background: $tuna;
          margin-left: auto;
          transition: width 0.5s, background 1s;
        }
        &-line-1 {
          width: 29px;
        }
        &-line-2 {
          width: 19px;
          margin: 7px 0px;
        }
        &-line-3 {
          width: 9px;
        }

        &.active {
          & > span {
            background: $primary;
            margin-left: auto;
          }
          .Menu__toggle-line-1 {
            width: 9px;
          }
          .Menu__toggle-line-2 {
            width: 19px;
            margin: 7px 0px 7px auto;
          }
          .Menu__toggle-line-3 {
            width: 29px;
          }
        }
      }

      @include sm {
        margin-right: 40px;
      }
      @include xl {
        display: none;
      }
    }

    // All GlobalHeader buttons
    .GlobalHeader__button {
      font-size: 12px;
      padding: 15px 20px;
      vertical-align: middle;
      margin-top: 0;
      &.Today {
        @include md {
          padding: 11px 19px;
        }
        @include xl {
          padding: 15px 20px;
        }
      }
    }



    .Login {
      margin-right: 12px;
      padding-left: 5px;
      padding-right: 10px;
      background-color: transparent;
      border-color: transparent;

      &:hover,
      &:active,
      &:focus {
        background: transparent;
        border-color: transparent;
      }

      @include xl {
        background: $whiteSand;
      }
    }

    .Login,
    .MyAccount {
      margin-right: 10px;

      @media (max-width: #{$screen-lg-min}) {
        border: none;
        &:hover {
          background: none;
        }
      }

      span {
        display: none;
      }
      @media(min-width: 400px){
        margin-right: 21px;
        padding-left: 20px;
        padding-right: 20px;
      }
      @include sm {
        margin-right: 29px;
      }
      @media(min-width: 1070px) {
        margin-right: 117px;
        background: $whiteSand;
        padding: 0 19px;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;

        &:hover,
        &:active,
        &:focus {
          background: $white;
          color: $primary;
          border-color: $primary;
        }

        span {
          display: inline-block;
        }
        svg {
          margin-left: 10px;
        }
      }
      @include xl {
        margin-right: 0px;
        padding: 0 20px;
        svg {
          margin-left: 7px;
        }
      }
    }


    .Search {
      background: transparent;
      padding: 13px;
      margin-right: 20px;
      display: inline-block;
      border: $dune;
      margin-right: 0px;
      position: static;

      svg path {
        fill: $black;
      }
      &.active,
      &:hover,
      &:focus,
      &:active {
        background: transparent;
      }
      &:focus {
        svg path {
          fill: #000;
        }
      }
      @include sm {
        padding: 15px;
        margin-right: 7px;
      }
      @media(min-width: 1070px) {
        padding: 0;
        position: absolute;
        top: 53px;
        right: 117px;
      }
      @media (max-width: $screen-xl-min - 1) {
        border: none;
      }
      @include xl {
        background: $dune;
        border-radius: 5px 0 0 5px;
        height: 50px;
        width: 50px;
        display: block;
        margin: auto;
        position: absolute;
        top: 41px;
        right: 0px;
        line-height: 0;
        text-align: center;

        svg {
          margin-left: 0;
        }

        svg path {
          fill: $white;
        }

        &.active,
        &:hover,
        &:focus,
        &:active {
          background: $primary;

          svg path {
            fill: $white;
          }
        }
      }
    }

    .Guest,
    .Directory {
      padding-left: 0;
      padding-right: 0;
      border: none;

      &.active,
      &:hover,
      &:focus,
      &:active {
        background: transparent;
        color: $primary;

        svg path {
          fill: $primary;
        }
      }
    }

    .Guest,
    .Directory,
    .Today {
      display: none;
      text-transform: uppercase;
      cursor: pointer;
      white-space: nowrap;
      &.active,
      &:hover,
      &:focus {
        background: $white;
        color: $primary;

        svg path {
          fill: $primary;
        }
      }
      @include md {
        svg {
          margin-left: 10px;
        }
      }
      @include xl {
        display: inline-block;
        position: relative;
        margin-right: 41px;

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-bottom: 20px solid $primary;
          position: absolute;
          left: 0px;
          right: 0px;
          margin: auto;
          bottom: -35px;
          opacity: 0;
        }

        &.active {
          &:after {
            opacity: 1;
            transition: opacity 0.5s;
          }
        }

        span {
          display: inline-block;
          position: relative;
        }


      }
      @media(min-width: 1430px){
        margin-right: 51px;
      }
    }
    .Today {
      @media(min-width: 1215px) {
        margin-right: 48px;
        display: inline-block;
      }
    }

    @include xl {
      .Directory,
      .Guest {
        transition: background 0.2s, color 0.2s;
        &.active,
        &:hover {
          color: $primary;

          svg path {
            fill: $primary;
          }
        }
      }

      .Today {
        border: 1px solid $primary;
        transition: background 0.2s, color 0.2s;
        padding: 15px 20px;
        line-height: initial;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        &.active {
          &:after {
            bottom: -37px;
          }
        }
      }
    }
  }
  &__bottom {
    position: absolute;
    z-index: 10;
    opacity: 0;
    right: 0px;
    width: 100%;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
    @include xl {
      border-top: 1px solid $gallery;
    }
    &.active {
      opacity: 1;
      right: 0px;
      pointer-events: initial;
      @media (max-width: #{$screen-sm-min}) {
        top: 86px;
      }
    }

    .Mobile {
      &__Links {
        display: block;
        position: relative;
        z-index: 1;
        font-size: 0px;
        line-height: 0;
      }

      &__Today button {
        display: block;
        width: 100%;
        background: $primary;
        color: $white;
        border: none;
        font-size: 12px;
        padding: 18px 0px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
      }

      &__Guest,
      &__Directory {
        display: inline-block;
        width: 50%;
        border-bottom: 1px solid $gallery;
        button {
          font-size: 12px;
          border: none;
          background-color: $whiteSand;
          text-align: center;
          width: 100%;
          padding: 18px 0px;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 1px;
        }

        &.active,
        &.inactive {
          position: relative;
          button {
            background-color: white;
          }
          &:after {
            content: "";
            position: absolute;
            display: block;
            height: 2px;
            width: 100%;
            background: $primary;
            bottom: 0px;
            left: 0px;
          }
        }
      }

      &__Guest {
        border-right: 1px solid $gallery;
      }
    }
    .Nav {
      display: block;
      background: $white;
      height: calc(100vh - 195px);
      overflow: auto;
      padding-bottom: 70px;

      @include sm {
        height: calc(100vh - 240px);
      }
      &__item {
        display: block;
        border-bottom: 1px solid $gallery;
        font-size: 14px;
      }

      &__icon {
        transform: rotate(-90deg);
        margin-right: 20px;
      }

      &__link {
        color: $dune;
        text-decoration: none;
        display: block;
        padding: 15px 20px;
        font-weight: 500;
        letter-spacing: normal;
      }
    }

    @include xl {
      position: static;
      display: block;
      opacity: 1;
      pointer-events: initial;
      border-bottom: 1px solid $gallery;

      .Mobile__Links {
        display: none;
      }
      .Nav {
        padding: 0px 55px;
        display: block;
        margin: 0 auto;
        height: 69px;
        max-width: 1600px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        flex-direction: row;
        box-sizing: border-box;

        &__item {
          display: inline-block;
          position: relative;
          font-size: 12px;
          border-bottom: none;
          overflow: hidden;
          height: 69px;
          flex-grow: 1;
          line-height: 22px;

          &:last-child {
            &:after {
              display: none;
            }
          }

          &:before {
            content: "";
          }

          &:after {
            content: "";
            display: block;
            background: $gallery;
            height: 20px;
            width: 1px;
            position: absolute;
            top: 25px;
            right: 0;
          }
        }

        &__link {
          padding: 25px 20px;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 2px;
          position: relative;
          text-align: center;
          transition: color 0.2s linear;
          &:after {
            content: "";
            display: block;
            height: 10px;
            width: 100%;
            position: absolute;
            bottom: -25px;
            left: 0;
            background: $primary;
            transform: rotate(-2deg);
            transition: bottom 0.2s ease-in-out;
          }

          &:hover,
          &.active {
            color: $primary;

            &:after {
              content: "";
              bottom: -2px;
            }
          }
        }

        &__icon {
          display: none;
        }
      }
    }
  }
}
.noTime {
  @media (max-width: #{$screen-md-min}) {
    padding-bottom: 0;
    .Opening {
      display: none;
    }
  }
}

@media all and (-ms-high-contrast:none) {
  .Header__top .Header__wrap {
    display:block;
    text-align:right;
  }

  .Header__top .Login, .Header__top .MyAccount, .Header__top .Today {
    display:inline-block;
    width:auto;
    vertical-align:middle;

    > * {
      vertical-align:middle;
    }
  }

  .Header__top .Today {
    margin-right:60px;
  }

  .Header__top .Login, .Header__top .MyAccount {
    line-height:43px;
  }
}

@media all and (-ms-high-contrast:none) and (min-width:1400px) {
  .Header__top .Opening {
    margin-top:16px;
    margin-right:20px;
  }
  .Header__top .Login, .Header__top .MyAccount {
    margin-right:0!important;
  }
}
@media all and (-ms-high-contrast:none) and (min-width:992px) {
  .Header__top .Opening__text, .Header__top .Opening__text--light {
    font-size:14px!important;
  }
}

@media all and (-ms-high-contrast:none) and (max-width:1520px) {
  .Header__top .Guest, .Header__top .Directory, .Header__top .Today, .Header__top .Opening {
    margin-right:20px;
  }
}

@media all and (-ms-high-contrast:none) and (max-width:1200px) {
  .Header__top .Login, .Header__top .MyAccount {
    background:transparent;
    padding:0 10px;

    span {
      display:none;
    }
  }
}

@media all and (-ms-high-contrast:none) and (max-width:1080px) {
  .Header__top .Search {
    right:88px;
  }
  .Header__top .Login, .Header__top .MyAccount {
    margin-right:67px;
  }
}


@media all and (-ms-high-contrast:none) and (max-width:1069px) {
  .Header__top .Search {
    padding:7px;
  }

  .Header__top .Guest, .Header__top .Directory, .Header__top .Today, .Header__top .Opening, .Header__top .Login, .Header__top .MyAccount {
    margin-right:10px;
  }
}

@media all and (-ms-high-contrast:none) and (max-width:995px) {
  .Header__top .Guest, .Header__top .Directory, .Header__top .Today, .Header__top .Opening, .Header__top .Login, .Header__top .MyAccount {
    margin-right:7px;
  }
}
