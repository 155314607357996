@import '../../../GlobalStyles/variables.scss';

.Footer {
  text-align: center;
  .Logo {
    width: 172px;
    display: inline-block;
    vertical-align: middle;
    @include md {
      width: 280px;
    }
  }

  &__top {
    padding: 30px 25px;
    display: block;
    max-width: 1270px;
    margin: 0 auto;

    @include lg {
      padding: 50px 25px;
      box-sizing: content-box;
    }

  &_social {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    font-size: 0;
    width: calc(100% - 172px);
    @include md {
      width: calc(100% - 280px);
    }

    &_twitter, &_facebook, &_instagram {
      display: inline-block;
      margin-left: 21px;
      height: 29px;
      &:first-child {
        margin-left: 0;
      }
      @include md {
        margin-left: 29px;
        height: 40px;
      }

      svg {
        height: 29px;
        width: 29px;
        @include md {
          height: 40px;
          width: 40px;
        }
        & path, & polygon {
          fill: $primary;
          }
        }
      }
    }
  }
  &__NavOuter {
    max-width: 1270px;
    margin: 0 auto;
    display: block;
    text-align: left;
    @include lg {
      padding: 0 25px;
      box-sizing: content-box;
    }
  }
  &__Nav {
    border-top: 1px solid $gallery;
    padding: 14px 0 12px;
    @include lg {
      padding: 0;
      display: flex;
      justify-content: space-between;
    }

    &_item {
      list-style-type: none;
      padding: 11px 25px;


      @include lg {
        display: inline-block;
        padding: 37px 10px 52px;

        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
      }

      & a {
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: normal;
        color: $dune;
        line-height: 1.63em;

        svg {
          transform: rotate(-90deg);
          display: inline-block;
          margin-right: 14px;
          height: 10px;
          width: 18px;

          @include lg {
            display: none;
          }
        }
      }
    }
  }
}
