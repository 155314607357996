@import "../../../GlobalStyles/variables.scss";

.Wrapper {
  display: block;
  width: 100%;
  max-width: 830px;
  margin: 30px auto;
  padding: 0 25px;
  position: relative;
  @include xl {
    box-sizing: content-box;
  }

  &_Wide {
    max-width: 1270px;
  }
  + .Wrapper{
    padding-top: 40px;
  }

  &_ExtraWide {
    max-width: 1320px;
  }

  ul, ol {
    padding-left: 20px;
    @include lg {
      padding-left: 0;
    }
  }
}


[data-iswide='true']{
  max-width: 1250px;
}

.filterWrap {
  margin: 18px auto !important;
}