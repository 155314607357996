.Wrapper {
  display: block;
  width: 100%;
  max-width: 830px;
  margin: 30px auto;
  padding: 0 25px;
  position: relative; }
  @media (min-width: 1400px) {
    .Wrapper {
      box-sizing: content-box; } }
  .Wrapper_Wide {
    max-width: 1270px; }
  .Wrapper + .Wrapper {
    padding-top: 40px; }
  .Wrapper_ExtraWide {
    max-width: 1320px; }
  .Wrapper ul, .Wrapper ol {
    padding-left: 20px; }
    @media (min-width: 992px) {
      .Wrapper ul, .Wrapper ol {
        padding-left: 0; } }

[data-iswide='true'] {
  max-width: 1250px; }

.filterWrap {
  margin: 18px auto !important; }
