form {
  //General Field styling
  label {
    display: inline-block;
    padding-bottom: 14px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    letter-spacing: normal;
  }

  input,
  textarea,
  select,
  input[type="file"] + label {
    display: block;
    width: 100%;
    padding: 13px 12px 15px;
    font-size: 14px;
    border: 1px solid $dune;
    border-radius: 2px;
    background-color: $white;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .checkboxouter__holder {
    display: block !important;
  }
  .checkboxouter {
    display: inline-block;
    line-height: 0;
    width: 20px;
    height: 20px;
    position: relative;
    vertical-align: top;
    transition: all 0.2s linear;
    input[type="checkbox"]:checked {
      background-color: #fff;
      border: 1px solid #028a3d;
      & + .checkboxouter__active {
        opacity: 1;
      }
      &:after {
        display: none;
      }
    }
    &--margin {
      margin-right: 19px;
      margin-bottom: 5px;
    }
    &__active {
      pointer-events: none;
      position: absolute;
      top: 50%;
      margin-top: -4px;
      left: 50%;
      margin-left: -5px;
      width: 10px;
      height: 8px;
      opacity: 0;
      transition: all 0.2s linear;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAgOCI+CiAgPGcgaWQ9Imljb25fdGljay1pY29uLWdyZWVuIiBkYXRhLW5hbWU9Imljb24gLyB0aWNrLWljb24tZ3JlZW4iIGNsaXAtcGF0aD0idXJsKCNjbGlwLXBhdGgpIj4KICAgIDxwYXRoIGlkPSJQYXRoIiBkPSJNMy45MjIsOGExLjA4NywxLjA4NywwLDAsMS0uNzUyLS4zMTJMMCw0LjIxOWwxLjQ3MS0xLjI1TDMuODU2LDUuNTk0LDguNDMxLDAsMTAsMS4xNTYsNC43MDYsNy42MjVBMS4wNCwxLjA0LDAsMCwxLDMuOTIyLDhaIiBmaWxsPSIjMDI4YTNkIi8+CiAgPC9nPgo8L3N2Zz4=");
      background-size: 10px 8px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  //Textarea
  textarea {
    max-width: 100%;
    min-width: 100%;
    max-height: 200px;
    min-height: 50px;
  }

  //File Attatchment
  input[type="file"] {
    position: absolute;
    z-index: -99;
    opacity: 0;
    + label {
      cursor: pointer;
    }
  }

  //Form specific styling
  [class$="__form"] {
    display: flex;
    flex-wrap: wrap;
  }

  .field-validation-error {
    border-color: red;
  }

  .Form {
    // Headers
    &__subheader {
      margin-top: 30px;
    }

    // Field
    &__field {
      width: 100%;
      padding: 30px 0px 0px 0px;

      &_error {
        display: block;
        color: red;
        margin-top: 3px;
        width: 100%;
      }

      &_wrap {
        position: relative;
        cursor: pointer;
      }

      &_icon {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 100%;
        border: 1px solid $dune;
        background-color: white;
        pointer-events: none;
        padding: 13px;
        width: 49px;
        box-sizing: border-box;
      }

      &_cbwrap {
        display: flex;
        margin: 15px 0px;
        position: relative;

        @include md {
          align-items: center;
        }
      }

      &_checkbox {
        margin-top: 4px;
        flex-shrink: 0;
        width: 20px;
        padding: 1px;
        height: 20px;
        display: inline-block;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 15px;

        + label {
          padding: 0px;
          margin-left: 35px;
          display: inline-block;
          width: 85%;
        }

        &:focus {
          border-color: #2d2926;
        }

        &:checked {
          background-color: white;
          border-color: #028a3d;
        }
      }

      &_radio {
        width: 20px;
        padding: 1px;
        height: 20px;
        border-radius: 50%;
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;

        + label {
          padding: 0px;
          margin-left: 10px;
          display: inline;
        }
        &:focus {
          border-color: #2d2926;
        }
        &:checked {
          background-color: white;
          border-color: #028a3d;

          &::after {
            content: "";
            display: block;
            background-color: #028a3d;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

//Enquiry form
.showEnquiry {
  &__false {
    opacity: 1;
    z-index: 0;
  }
  &__true {
    opacity: 0;
    z-index: 99;
  }
}

input:focus {
  border: 1px solid #cc3300;
}

@media screen and (max-device-width: 812px) {
  form {
    select,
    textarea,
    input {
      font-size: 16px;
      &:focus {
        font-size: 16px;
      }
    }
  }
}
