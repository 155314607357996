@import '../../../GlobalStyles/variables.scss';

.Directory {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 999;
  min-height: calc(100% - 130px);
  top: 195px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.5s;
  height: calc(100vh - 195px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include sm {
    top: 240px;
    height: calc(100vh - 240px);
  }
  @include xl {
    top: 189px;
    height: calc(100vh - 165px);
  }

  h2
  {
    padding: 0px 25px;
    margin-top: 30px;
    @include xl{
      padding: 0px;
      margin-top: 0px;
    }
  }

  @include xl {
    height: calc(100vh - 120px);
    padding: 61px 165px;
    &:before {
      content:'';
      display: block;
      position: fixed;
      top: 120px;
      left: 0px;
      height: 5px;
      z-index: 99;
      width: 100%;
      background: $primary;
    }

    top: 120px;
  }

  &__holder {
    position: relative;
  }

  &_Contact {
    display: none;

    @include xl {
      display: inline-block;
      padding: 12px 34px;
      background: $primary;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      color: $white;
      border-radius: 25px;
      text-decoration: none;
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
    transition: opacity 0.5s;
    h2 {
      @media (max-width: #{$screen-lg-min}) {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 26px;
      }
    }
   }

   &.inline {
     z-index: 998;
     position: relative;
     top: 0;
     height: auto;
     overflow: visible;

     @include xl {

      div[class*='back_desktop'] {
        top: 270px;
      }
    }
   }

   &__Notification {
     padding-bottom: 30px;
     background-color: $catSkillWhite;
     @include xl {
       display: none;
     }
   }

   &__back {
    display: block;
    padding: 30px 20px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    background: $whiteSand;
    width: 100%;
    text-transform: uppercase;
    text-align: left;
    cursor: pointer;

    @include xl {
      background: none;
      height:0px;
      padding: 0px;
    }


    svg {
      transform: rotate(90deg);
      margin-right: 20px;
      height: 10px;
      width: 18px;
    }

    &_desktop {
      display: none;

      @include xl {
        display: block;
        position: fixed;
        right: 60px;
        top: 210px;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    &_mobile {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 50px;
      @include xl {
        display: none;
      }
    }

  }

  &__Content {
    background: $catSkillWhite;
    padding-bottom: 50px;

    @include xl {
      background: transparent;
      padding: 0px;
    }
  }

  &__Sort {
    @include xl {
      border-top: 1px dashed $gallery;
      border-bottom: 1px dashed $gallery;
      padding: 15px 0px;
    }

    @media (max-width: #{$screen-lg-min}) {
      position: relative;
      left: -25px;
      top: 0;
      margin-right: -50px;
      margin-top: -10px;
      padding: 5px 0;
      background-color: #F4F8FA;
      border-bottom: 1px solid #EDEDED;
      &_label {
        letter-spacing: 1px;
      }
    }

    &_label {
      font-size: 18px;
      font-weight: 500;
      color: $dune;
      display: inline-block;
      vertical-align: middle;
      padding: 0px 20px;
      line-height: 28px;
    }

    &_input {
      display: inline-block;
      width: calc(100% - 135px);
      height: 40px;
      background: $white;
      vertical-align: middle;
      text-align: right;
      border: 1px solid #EDEDED;
      border-radius: 2px;
      position: relative;
      -webkit-appearance: none;
      padding: 0px 10px;
      text-align: left;

      + svg{
        position: absolute;
        transform: translate(-100%);
        top: 0px;
        height: 100%;
        width: 16px;
        padding: 0px 12px;
        border-left: 1px solid #EDEDED;
        box-sizing: content-box;
        pointer-events: none
      }

      @include xl {
        width: calc(100% - 115px);
      }
    }

    > a{
      display: none;
      @include xl{
        display: inline-block;
        margin-top: 0px;
      }
    }

    &_wrap {
      position: relative;
      @include xl {
        display: inline-block;
        width: 30%;
        float: right;
      }
    }
  }

  &__Cards {
    padding-top: 25px;
    font-size: 0px;
  }
}


.paginationHolder {
  text-align:center;
  padding: 20px;
  clear:both;
}
