@import "../../../GlobalStyles/variables.scss";

.Card {
  margin: 0px 25px 50px 25px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: block;
  text-decoration: none;
  text-align: center;
  // max-width: 390px;
  height: 537px;

  @include sm-only {
    margin: 0px 0px 50px 0px;
  }

  @include sm {
    max-width: 400px;
  }

  &.TagTop {
    border-radius: 10px 0px 10px 10px;
  }

  &__noSlider {
    margin: 0px 0px 25px 0px;
  }

  &__Image {
    // margin-bottom: 5px;
    height: 220px;
    width: 100%;
  }

  &__Content {
    position: relative;
    padding: 20px 35px;
    margin: -5px 0px 50px -5px;
    border-radius: 0px 0px 10px 10px;
    user-select: none;
    text-align: left;
    height: 100%;

    @include xl {
      padding: 40px 40px;
    }

    h3 {
      min-height: 63px;
      font-size: 24px;
      margin-left: 0px;
      display: block;
      color: $dune;

      a {
        color:inherit;
      }
    }

    /*p::after {
      content: '';
      position: absolute;
      top: 60px;
      right: 0;
      height: 24px;
      width: 40%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7) 50%);
    }*/
    &.blueBg {
      background: $catSkillWhite;
    }

    &.whiteBg {
      background: $white;
    }

    &__Label {
      display: block;
      margin-top: 30px;
      text-decoration: underline;
      color: $primary;
    }
  }

  &__ContentWrap {
    p {
      font-size: 16px;
      line-height: 26px;
      color: $dune;
    }
  }

  &__Tag {
    font-size: 12px;
    color: $white;
    font-weight: 500;
    background: $primary;
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 6px 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 0 0 0 5px;

    &.disabled {
      display: none;
    }

    &.Top {
      border-radius: 0 0 0 5px;
    }
  }
}
.leftalign {
  text-align: left;
}
