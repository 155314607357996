@import '../../../GlobalStyles/variables.scss';

.GuestServices{
  display: block;
  position: fixed;
  width: 100%;
  background: $white;
  z-index: 999;
  top: 195px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.5s;
  height: calc(100vh - 195px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include sm{
    top: 240px;
    height: calc(100vh - 240px);
  }
  @include xl{
    top: 120px;
    padding: 55px 245px;
    height: calc(100vh - 120px);
    &:before{
      content: '';
      display: block;
      position: fixed;
      z-index: 99;
      top: 120px;
      left: 0px;
      height: 5px;
      width: 100%;
      background: $primary;
    }
  }

  @include lg{
    top: 120px;
    padding: 55px 245px;
  }

  &.active{
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
    transition: opacity 0.5s;
  }

  &.inline {
    z-index: 998;
    position: relative;
    top: 0;
    height: auto;
    overflow: visible;

    div[class*='Carousel__Wrap'] {
      padding-left: 25px;
      padding-right: 25px;
    }

    @include xl {

      div[class*='Content'] {
        max-width:$screen-xl-min;
        margin:0 auto;
      }

      div[class*='back_desktop'] {
        top: 62px;
      }
    }
  }


  &__back{
    display: block;
    padding: 30px 20px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    background: $whiteSand;
    width: 100%;
    text-transform: uppercase;
    text-align: left;
    cursor: pointer;

    @include lg{
      padding: 0px;
      background: none;
    }

    svg{
      transform: rotate(90deg);
      margin-right: 20px;
      height: 10px;
      width: 18px;
      cursor: pointer;
    }


    &_desktop{
      display: none;

      @include lg{
        display: block;
        position: absolute;
        right: 60px;
        top: 90px;

        svg{
          height: 20px;
          width: 20px;
        }
      }
    }

    &_mobile{
      @include lg{
        display: none;
      }
    }
  }

  &__Description{
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;

    @include lg {
      width: 50%;
    }
    @include xl{
      width: 33.3%;
    }

    &_Title{
      padding: 0px 25px;
      margin-top: 30px;
      @include lg{
        margin-top: 0px;
        padding-left: 0px;
      }
    }

    &_Content{
      display: block;
      padding: 0px 25px;
      @include lg{
        padding-left: 0px;
      }

      li {
        margin-left: 20px;
      }
    }

    &_TrainedBy{
      display: block;
      margin-top: 0px;
      padding: 30px 25px;

      &:empty {
        padding: 0; margin:0
      }
      
      @include lg{
        padding-left: 0px;
        margin-top: 40px;
      }

      > img{
        margin-top: 15px;
      }

      span{
        a{
          padding-left: 5px;
        }
      }

      img{
        height: 100px;
        width: 100%;
        max-width: 500px;
        display: block;
        object-fit: cover;
      }

    }

    &_Phone{
      display: block;
      padding: 0px 25px;
      b {
        font-weight: 500;
      }
      @include lg{
        padding-left: 0px;
      }
    }

    > a{
      margin: 30px 0px;
      width: 100%;
      border-radius: 0px;

      @include lg{
        border-radius: 25px;
        width: auto;
      }
    }
  }

  &__Carousel__Wrap{
    padding: 30px 0px;
    @include xl{
      padding: 0px;
      background-color: transparent;
    }
    [class="slick-dots"] {
      bottom: -44px;
      @include xl {
        bottom: -65px;
      }
    }
  }
  &__Carousel__Single {
    padding-bottom: 103px;
    @include xl {
      padding-bottom: 0;
    }
  }

  &__Content{
    display: block;
  }

  &__Opening{
    display: block;

    @include lg{
      padding: 40px 165px
    }

    &_desktop{
      display: none;

      @include lg{
        display: block;
      }
    }

    &_mobile{
      @include lg{
        display: none;
      }

      svg{
        transform: rotate(180deg);
        position: absolute;
        right: 25px;
      }

      &.active{
        svg{
          transform: rotate(0deg);
        }
      }

      &_desktop{
        font-size: 24px;
        line-height: 34px;
        color: $dune;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }



  &__Accordion{
    height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s;
    margin-bottom: 50px;
    display: block;

    @include lg{
      display: inline-block;
      width: 29%;
      vertical-align: top;
    }


    &_Wrap{
      padding: 25px;
    }

    &_Heading{
      display: block;
      font-weight: 500;
      font-size: 16px;
      color: $dune;
      line-height: 30px;
      height: 30px;
    }

    &_Text{
      display: block;
      font-weight: 300;
      font-size: 14px;
      color: $dune;
      line-height: 30px;
      height: 30px;
    }


    @include lg{
      &_Wrap{
        padding: 25px 0px;
      }
    }

    &.active{
      height: auto;
      opacity: 1;
    }
  }


  &__Carousel{
    &__Wrap{
      display: block;
      width: 100%;

      @include lg {
        display: inline-block;
        width: 50%;
      }

      @include xl{
        display: inline-block;
        width: 66.6%;
        vertical-align:middle;
      }

    }

    @include sm{
      &__Single{
        display: inline-block;
        width: 50%;
      }
    }

    @include lg{
      &__Single{
        width: 100%;
      }
    }

    @include xl{
      &__Single{
        display: inline-block;
        width: 50%;
        vertical-align:top;
      }
    }

  }

}
