/* Slider */
.slick-list {
  .slick-loading & {
    background: #fff url("../../../Assets/icons/load-icon.svg") center center
      no-repeat;
  }
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;

  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: transparent;
  }
  &:hover {
    svg path {
      fill: $primary!important;
    }
  }

  &.slick-disabled {
    opacity: 0.25;
  }
  @media (max-width: #{$screen-xl-min}) {
    display: none;
  }

  svg {
    width: 32px;
    height: 17px;

    & path {
      transition: all 0.2s linear;
      fill: $iron;
    }
  }
}

.slick-prev {
  left: -25px;
  margin-top: -40px;
  margin-left: -40px;
  transform: rotate(90deg);

  [dir="rtl"] & {
    left: auto;
    right: -25px;
  }
}

.slick-next {
  right: -25px;
  margin-top: -40px;
  margin-right: -40px;
  transform: rotate(-90deg);

  [dir="rtl"] & {
    left: -25px;
    right: auto;
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    padding: 0;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;

      &:hover,
      &:focus {
        outline: none;
      }
    }

    button:before {
      content: "";
      width: 6px;
      height: 6px;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.2s linear;
      border: 2px solid $black;
      background: $black;
      box-sizing: border-box;
    }

    // Active dots
    &.slick-active {
      button:before {
        width: 10px;
        height: 10px;
        background: transparent!important;
      }
    }

    // Not Active Dot
    &:not(.slick-active) {
      button {
        cursor: pointer;
      }
    }
  }
}

.Today_Today__Carousel__Single__PFj3z .slick-slider {
  margin-bottom: 40px;
  .slick-dots li.slick-active button:before {
    width: 10px;
    height: 10px;
  }
}

/* Dark Mode */

.Slider-dark-bg {
  .slick-dots li {
    button:before {
      background: $white;
      border-color: $white;
    }
  }

  .slick-arrow {
    svg path {
      fill: $white;
    }
  }
}
